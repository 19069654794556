import React, { useState, useEffect } from "react";
import {graphql} from 'gatsby';
import '../css/rahul-styles.css';
import Header from '../components/header';
import Footer from '../components/footer';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import validator from 'validator';
import swal from 'sweetalert';
import axios from 'axios';
import "../css/rahul-styles.css";
import placeholder from "../images/placeholder-wide.png";

import { Helmet } from "react-helmet";
import Layout from "../components/layout";


const EventDetail = ({ data }) => {
  const [selected, setSelected] = useState([]);
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [number, setNumber] = useState('')
  const [qualification, setQualification] = useState('')
  const [destination, setDestination] = useState('')
  const [abroad, setAbroad] = useState('')
  // const [interested, setInterested] = useState([])
  const [questions, setQuestions] = useState('')
  const [policy, setPolicy] = useState(false) 
  const [formError, setFormError] = useState({})
  const errors = {}

  const handleChange = (e) => {
    if(e.target.name === 'firstName') {
      setFirstName(e.target.value)
    } else if (e.target.name === 'lastName') {
      setLastName(e.target.value)
    } else if (e.target.name === 'email') {
      setEmail(e.target.value)
    } else if (e.target.name === 'number') {
      setNumber(e.target.value)
    } else if (e.target.name === 'qualification') {
      setQualification(e.target.value)
    } else if (e.target.name === 'destination') {
      setDestination(e.target.value)
    } else if (e.target.name === 'questions') {
      setQuestions(e.target.value)
    } else if (e.target.name === 'abroad') {
      setAbroad(e.target.value)
    }
  }

  const handlePolicy = (e) => {
    const policyInput = e.target.checked 
    setPolicy(policyInput) 
  }

  const runValidations = () => {
    if(firstName.trim().length === 0) {
      errors.firstName = "Required"
    } 
    // if (lastName.trim().length === 0) {
    //   errors.lastName = "Required"
    // }
    if (email.trim().length === 0) {
      errors.email = "Required"
    } else if (!validator.isEmail(email)) {
      errors.email = "Invalid E-Mail Format"
    }
    if (number.trim().length === 0) {
      errors.number = "Required"
    } else if (number.trim().length < 10) {
      errors.number = "Atleast 10 digits required"
    } 
    if (!qualification) {
      errors.qualification = "Required"
    }
    if (!destination) {
      errors.destination = "Required"
    }
    // if (!abroad) {
    //   errors.abroad = "Required"
    // }
    // if( selected.length === 0 ) {
    //   errors.selected = "Select atleast one"
    // }
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    runValidations()

    // const multiSelectOptions = selected.map(item => {
    //   return (
    //     item.label
    //   )
    // })

    // const helpSelectOptions = interested.map(item => {
    //   return (
    //     item.label 
    //   )
    // })

    // console.log(helpSelectOptions)
    // console.log(multiSelectOptions)

    if(Object.keys(errors).length === 0) {
      setFormError({})
      const formData = {
        firstName: firstName,
        // lastName: lastName,
        email: email,
        number: number,
        destination: destination,
        // interested: interested,
        qualification: qualification,
        // questions: questions,
        policy: policy,
        // abroad: abroad
        // areaStudy: multiSelectOptions
      }


      setFirstName('')
      // setLastName('')
      setEmail('')
      setNumber('')
      setDestination('')
      // setInterested([])
      setQualification('')
      // setQuestions('')
      // setAbroad('')
      // setSelected([])
      setPolicy(false)

      // const arrSelected = selected.map(item => {
      //   return item.label
      // })

      swal("Done!", "Our Admissions team will get in touch with you soon.", "success");

      // handleCancel()

      axios.post('https://api.leadsquared.com/v2/LeadManagement.svc/Lead.Capture?accessKey=u$r818cc16ada0b011c2981128aabe70284&secretKey=65a4a7aea451fbd17be5958bff3d23abe3bcb92f', [
        {
          "Attribute": "FirstName",
          "Value": firstName
        },
        // {
        //   "Attribute": "LastName",
        //   "Value": lastName
        // },
        {
          "Attribute": "EmailAddress",
          "Value": email
        },
        {
          "Attribute": "Phone",
          "Value": number
        },
        {
          "Attribute": "SearchBy",
          "Value": "Phone"
        },
        {
          "Attribute": "mx_Student_program_level",
          "Value": qualification
        },
        {
          "Attribute": "mx_Target_Destination",
          "Value": destination 
        },
        // {
        //   "Attribute": "mx_Target_Discipline",
        //   "Value": JSON.stringify(arrSelected)
        // },
        {
          "Attribute": "ProspectID",
          "Value": window ? window.MXCProspectId ? window.MXCProspectId : "xxxxxxxx-d168-xxxx-9f8b-xxxx97xxxxxx" : "xxxxxxxx-d168-xxxx-9f8b-xxxx97xxxxxx"
        },
        // {
        //   "Attribute": "mx_Help_With",
        //   "Value": JSON.stringify(helpSelectOptions)
        // },
        // {
        //   "Attribute" : "mx_Timeline_for_Going_Abroad",
        //   "Value" : abroad
        // },
        // {
        //   "Attribute": "mx_Questions_from_Student",
        //   "Value": questions
        // },
        {
          "Attribute": "mx_Read_TWG_Privacy_Policy",
          "Value": policy
        },
        {
          "Attribute": "Source",
          "Value": "Website Enquiry"
        }
      ])
      .then(response => {
      })
      .catch(err => {
        alert(err.message)
        // console.log(err.message)
      })

    } else {
      setFormError(errors)
    }
  }

  
  useEffect(() => {
    const webinarStartDate = data.wordpressWpEvents.acf.event_date;
    const webinarEndDate = data.wordpressWpEvents.acf.event_end_date;
  
    const dateObj = customParseDate(webinarEndDate ? webinarEndDate : webinarStartDate);


    if (isNaN(dateObj.getTime())) {
        console.error('Invalid date:', webinarStartDate, webinarEndDate );
    } else {
     
        const endDate = dateObj.getTime();
        console.error('Invalid date2:', dateObj, endDate );
        const timer = setInterval(function() {
            const now = new Date().getTime();
           // console.log("EndDate->"+endDate+"--"+now)
            const t = parseInt(endDate - now);
                    
            if (t > 0) {
          
                let days = Math.floor(t / (1000 * 60 * 60 * 24));
                let hours = Math.floor((t % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                let mins = Math.floor((t % (1000 * 60 * 60)) / (1000 * 60));
                let secs = Math.floor((t % (1000 * 60)) / 1000);

                // Update timer elements only if they exist
                const timerDaysElement = document.getElementById("timer-days");
                if (timerDaysElement) {
                    timerDaysElement.innerHTML = days + "<span class='label'>DAYS</span>";
                }
                const timerHoursElement = document.getElementById("timer-hours");
                if (timerHoursElement) {
                    timerHoursElement.innerHTML = ("0" + hours).slice(-2) + "<span class='label'>HRS</span>";
                }
                const timerMinsElement = document.getElementById("timer-mins");
                if (timerMinsElement) {
                    timerMinsElement.innerHTML = ("0" + mins).slice(-2) + "<span class='label'>MIN</span>";
                }
                const timerSecsElement = document.getElementById("timer-secs");
                if (timerSecsElement) {
                    timerSecsElement.innerHTML = ("0" + secs).slice(-2) + "<span class='label'>SEC</span>";
                }
            } else {
 
                const timerElement = document.getElementById("timer");
                if (timerElement) {
                    timerElement.innerHTML = "Event is expired!";
                }
                clearInterval(timer); // Clear the interval when the event is expired
            }
        }, 100);

        // Clean up the interval on component unmount
        return () => clearInterval(timer);
    }
}, []);




function customParseDate(dateString) {
  const months = {
    January: '01',
    February: '02',
    March: '03',
    April: '04',
    May: '05',
    June: '06',
    July: '07',
    August: '08',
    September: '09',
    October: '10',
    November: '11',
    December: '12'
  };
  
  // Split date string into date and time parts
  const [datePart, timePart, period] = dateString.split(' ');
  
  // Extract year, month name, and day
  const [year, monthName, day] = datePart.split('-');
  // Extract hours, minutes, and seconds
  const [hours, minutes, seconds] = timePart.split(':');
  
  // Convert month name to month number
  const month = months[monthName];
  
  // Convert 12-hour clock to 24-hour clock
  let hour = parseInt(hours);
  if (period === 'PM' && hour < 12) {
    hour += 12;
  }
  if (period === 'AM' && hour === 12) {
    hour = 0;
  }
  
  // Construct ISO date string
  const isoDateString = `${year}-${month}-${day}T${hour.toString().padStart(2, '0')}:${minutes}:${seconds}`;
  
  // Create Date object
  return new Date(isoDateString);
  }


  const post = data.wordpressWpEvents; 
  const metatitle = post.acf.meta_title ;
	const metadescription = post.acf.meta_description; 
const focus_keyphrase = post.acf.focus_keyphrase; 
const canonical_url = post.acf.canonical_url; 


  const eventDate = post.acf.event_date;
  const eventEndDate = post.acf.event_end_date ? post.acf.event_end_date : post.acf.event_date;
 
  const eventDateType = new Date(eventDate);
  const [dateStr, timeStr, amOrPm] = eventDate.split(' ');
	const [year, month, day] = dateStr.split('-');
	const [hour, minute, second] = timeStr.split(/:| /);

  const [dateStr2, timeStr2, amOrPm2] = eventEndDate.split(' ');
	const [year2, month2, day2] = dateStr2.split('-');
	const [hour2, minute2, second2] = timeStr2.split(/:| /);
								
	// Convert the month name to a number
	const monthNum = new Date(Date.parse(`${month} 1, ${year}`)).getMonth();
  const monthNum2 = new Date(Date.parse(`${month2} 1, ${year2}`)).getMonth();
		// Create a new Date object with the parsed components
	const dateObj = new Date(year, monthNum, day, hour, minute, second);
  const dateObj2 = new Date(year2, monthNum2, day2, hour2, minute2, second2);


  const finalDate = ('0' + dateObj.getDate()).slice(-2);
  const finalDate2 = ('0' + dateObj2.getDate()).slice(-2);
  const monthname = dateObj.toLocaleString([], {
    month: 'short',
  });
  const monthname2 = dateObj2.toLocaleString([], {
    month: 'short',
  });
  //const eventHour = dateObj.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
  const eventHour = hour +':'+ minute +' '+ amOrPm;
  const nth = function(d) {
    if (d > 3 && d < 21) return 'th';
    switch (d % 10) {
        case 1:  return "st";
        case 2:  return "nd";
        case 3:  return "rd";
        default: return "th";
    }
  };
  var headerBackground = "/static/americanbanner-31161936b50af35a68b688be75f818f4.png";
  var headerBackgroundMobile = "/static/americanbanner-31161936b50af35a68b688be75f818f4.png";
if(post.acf.event_banner){
	headerBackground = post.acf.event_banner.source_url;
  headerBackgroundMobile = post.acf.event_banner.source_url;
}
if(post.acf.event_banner_mobile){
	var headerBackgroundMobile = post.acf.event_banner_mobile.source_url;
}

  return (
    <div>
      <Layout>
        <Helmet>
        <title>{metatitle}</title>
          <meta name="description" content={metadescription} />
          {(() => {if(post.acf.focus_keyphrase != '' && post.acf.focus_keyphrase != null) {return (<meta name="keywords" content={focus_keyphrase} /> )} })()}
          {(() => {if(post.acf.canonical_url != '' && post.acf.canonical_url != null) {return (<link rel="canonical" href={canonical_url} /> )} })()} 
           {(() => {if(post.acf.dynamic_schema != '' && post.acf.dynamic_schema != null) {return (<script type="application/ld+json">{JSON.stringify (JSON.parse(post.acf.dynamic_schema))}</script> )} })()}  
          <link rel="icon" href="https://assets.theworldgrad.com//gatsby-assets/xtra/twg_favicon_ymgrbw.png" />
            </Helmet>
        {/* <Header /> */}
        <div>
        <div class="newStyle font-poppins">
        <div className="container">
		 <ol class="transparentbreadcrumb breadcrumb breadcrumb-new">
											<li class="breadcrumb-item">
												<a href="/">Home </a>
											</li>
                      <li class="breadcrumb-item">
												<a href="/event-listing/">Events </a>
											</li>
											<li class="breadcrumb-item active" dangerouslySetInnerHTML={{ __html: post.title }} />
										</ol>
				</div>

<section class="d-none d-sm-block wow animate__fadeIn bg-light-gray padding-120px-tb sm-padding-100px-tb position-relative bg-cover480br extra-big-section"   style={{ backgroundImage: "url(" + headerBackground + ")" }} >
     <div class=" bg-medium-slate-blue"></div>
     <div class="container">
       <div class="row">
         <div class="col-12 col-xl-10 col-lg-10 col-md-10 position-relative page-title-large ">
          </div>
       </div>
     </div>
   </section>

   <section class="d-none d-xs-block wow animate__fadeIn bg-light-gray padding-120px-tb sm-padding-100px-tb position-relative bg-cover480br extra-big-section"  style={{ minHeight: '170px', backgroundImage: "url(" + headerBackgroundMobile + ")" }} >
     <div class=" bg-medium-slate-blue"></div>
     <div class="container">
       <div class="row">
         <div class="col-12 col-xl-10 col-lg-10 col-md-10 position-relative page-title-large ">
          </div>
       </div>
     </div>
   </section>
   </div>
          <div className="container-fluid p-0">
            <div className="row m-0">
              <div className="p-0 col-12">
                <div className="blog-details-img-wrap event-details-img-wrap">
                  
                  <div className="blog-detail-content">
                    <div className="container">
             

                    {post.acf.event_location === "Online" ?
                          (  
                            <>
                              {post.acf.online_link == null ?
                                (<a className="btn btn-warning online-link">{post.acf.event_location} </a>)
                                :(<a className="btn btn-warning online-link" href={post.acf.online_link.url} target={post.acf.online_link.target} dangerouslySetInnerHTML={{ __html: post.acf.online_link.title }} />)
                              }
                            </>
                           )
                          : null
                        }


{post.acf.event_end_date ? (
        <p className="event-date event-end-date">
          <span>STARTS - {finalDate} {monthname}  </span>  <span> ENDS - {finalDate2} {monthname2}</span>
        </p>

      ) : (
        <p className="event-date">
          <span>{finalDate}</span> <span>{monthname}</span>
          
        </p>
      )}


                      {post.acf.event_location === "Online" ?
                          (<p className="blog-detail-subtitle">
                            {eventHour} 
                            </p>)
                          :(<p className="blog-detail-subtitle">{post.acf.event_location} {eventHour}</p>)
                        }
                    </div>
                 
                  </div>
                </div>
              </div>
            </div>
            <div className="container py-5">
              <div className="row pb-5">
                <div className="col-lg-6 updated-blog-details">
                 <div className="blog-detail-content d-none">
                      <div className="blog-detail-inner-content">
                   
                        <h2 className="blog-detail-title" dangerouslySetInnerHTML={{ __html: post.title }}></h2>
                      </div>
                  </div>
                  <h1 class="alt-font font-weight-500 letter-spacing-minus-1px line-height-50 sm-line-height-45 xs-line-height-30  margin-10px-bottom" dangerouslySetInnerHTML={{ __html: post.title }} />
           <div class=" alt-font text-white no-margin-bottom" dangerouslySetInnerHTML={{ __html: post.acf.event_subtitle }} />
        
                  <div dangerouslySetInnerHTML={{ __html: post.content }} />
                </div>
                <div className="col-lg-6 pl-lg-5 mt-lg-0 mt-5 blog-detail-form-wrap text-center">
                  <p id="timer">
                    <span id="timer-days"></span>
                    <span id="timer-hours"></span>
                    <span id="timer-mins"></span>
                    <span id="timer-secs"></span>
                  </p>
                  <div className="row top-row-img ">
                    <div className="col-12 w-100 p-0">
                      <div className="header-text">
                        <h2 className="main-heading-text">Register Here</h2>
                      </div>
                    </div>
                  </div>
                  <div className="container form-container text-left">
                    <div className="row">
                      <div className="col-12 mb-1">
                        <p className="sub-heading mb-0 text-color">
                          PERSONAL
                        </p>
                      </div>
                      <div className="col-md-6">
                        <label className="form-label form-label-popup">NAME*</label> {formError.firstName &&  <span className="text-danger error-message-text"> {formError.firstName} </span> }
                        <input
                          name='firstName'
                          value={firstName}
                          onChange={handleChange}
                          type="text"
                          className="form-control form-control-popup text-color" placeholder="Type Here"
                        />
                      </div>
                      {/* <div className="col-md-6">
                        <label className="form-label  form-label-popup">LAST NAME*</label> {formError.lastName && <span className="text-danger error-message-text"> {formError.lastName} </span>}
                        <input
                          name='lastName'
                          value={lastName}
                          onChange={handleChange}
                          type="text"
                          className="form-control form-control-popup"
                          placeholder="Type Here"
                        />
                      </div> */}
                      <div className="col-md-6">
                        <label className="form-label form-label-popup">E-MAIL*</label> {formError.email && <span className="text-danger error-message-text"> {formError.email} </span>}
                        <input
                          name='email'
                          value={email}
                          onChange={handleChange}
                          type="email"
                          className="form-control form-control-popup"
                          placeholder="Type Here"
                        />
                      </div>
                      <div className="col-md-6">
                        <label className="form-label form-label-popup">PHONE NUMBER*</label> {formError.number && <span className="text-danger error-message-text"> {formError.number} </span>}
                        <input
                          name='number'
                          value={number}
                          onChange={handleChange}
                          type="number"
                          className="form-control form-control-popup"
                          placeholder="+91"
                        />
                      </div>
                    </div>
                    <div className="row mt-1">
                      <div className="col-12 mb-1">
                        <p className="sub-heading mb-0 text-color"> EDUCATION </p>
                      </div>
                      <div className="col-md-6">
        
                        <label className="form-label form-label-popup"> QUALIFICATION YOU'RE SEEKING* </label>
        
                        <select
                          className="form-select form-select-popup"
                          name="qualification"
                          value={qualification}
                          onChange={handleChange}
                        >
                          <option selected> Select One </option>
                          <option value="Undergraduate"> Undergraduate </option>
                          <option value="Postgraduate"> Postgraduate </option>
                        </select>
                        {formError.qualification && <span className="text-danger error-message-text"> {formError.qualification} </span>}
                      </div>
                      <div className="col-md-6">
        
                        <label className="form-label form-label-popup"> PREFERRED EDUCATION DESTINATION* </label>
                        <select
                          name="destination"
                          value={destination}
                          onChange={handleChange}
                          className="form-select form-select-popup"
                        >
                          <option selected> Select One </option>
                          <option value="Australia"> Australia </option>
                          <option value="Dubai"> Dubai </option>
                          <option value="United Kingdom"> United Kingdom </option>
                          <option value="United States"> United States </option>
                          <option value="Other"> Other </option>
                        </select>
                        {formError.destination && <span className="text-danger error-message-text"> {formError.destination} </span>}
                      </div>
                      {/* <div className="col-12 multi-select-container">
                      <label className="form-label form-label-popup"> WHEN ARE YOU PLANNING TO GO ABROAD? </label>
        
                        <select
                          className="form-select form-select-popup"
                          name="abroad"
                          value={abroad}
                          onChange={handleChange}
                        >
                          <option selected> Select One </option>
                          <option value="Within 3 months"> Within 3 months </option>
                          <option value="In 3 - 6 months"> In 3 - 6 months </option>
                          <option value="In 6 - 12 months"> In 6 - 12 months </option>
                          <option value="After 12 months"> After 12 months </option>
                          <option value="Not planning to go abroad"> Not planning to go abroad </option>
                        </select>
                        {formError.abroad && <span className="text-danger error-message-text"> {formError.abroad} </span>}
                      </div> */}
                      <div className="col-md-6 multi-select-container">
                        {/* <label className="form-label form-label-popup">DO YOU NEED ANY HELP WITH?</label>
                        <MultiSelect
                          disableSearch
                          options={helpOptions}
                          value={interested}
                          onChange={setInterested}
                          labelledBy="Select atleast one"
                        />
                        {formError.interested && <span className="text-danger error-message-text" > {formError.interested} </span>} */}
                      </div>
                      {/* <div className="col-md-6">
                        <label className="form-label form-label-popup">
                          DO YOU NEED ANY HELP WITH?
                        </label>
                        <select
                          name="interested"
                          value={interested}
                          onChange={handleChange}
                          className="form-select form-select-popup"
                          aria-label="Default select example"
                        >
                          <option selected>Select one</option>
                          <option value="Free Trial Classes">Free Trial Classes</option>
                          <option value="English Test Preparation"> English Test Preparation </option>
                          <option value="Loan Application Assistance">Loan Application Assistance</option>
                          <option value="Visa Counselling">Visa Counselling</option>
                        </select>
                      </div> */}
                    </div>
                    <div className="row">
        
                      {/* <label style={{ marginLeft: "15px" }} className="form-label form-label-popup">DO YOU HAVE ANY OTHER QUESTIONS?</label>
                      <div className="col-12">
                        <input
                          type="text"
                          className="form-control form-control-popup"
                          placeholder="Ask a question"
                          onChange={handleChange}
                          name="questions"
                          value={questions}
                        />
                      </div> */}
                      <div className="col-12" style={{ marginLeft: "20px" }}>
        
                        <label className="form-check-label" style={{ fontWeight: "500" }} >
                          <input
                            checked={policy}
                            onChange={handlePolicy}
                            className="form-check-input"
                            type="checkbox"
                          />
                          I have read and understood <a href="/privacypolicy"> The WorldGrad Privacy Policy </a>
                        </label>
                      </div>
                    </div>
                    <div className="row justify-content-center">
                      <div className="col-md-4 button-body">
                        <a href="#">
                          <button
                            type="button"
                            className="btn btn-warning"
                            onClick={handleSubmit}
                            disabled={!policy}
                          >SUBMIT</button>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <Footer /> */}
      </Layout>
    </div>
  )
}

export default EventDetail

export const WorldgradEventQuery = graphql`
  query WorldgradEventQuery( $id: String!){
    wordpressWpEvents(id: { eq: $id }) {
      title
      slug
      content
      acf {
        meta_title
        meta_description
        focus_keyphrase
        canonical_url
        dynamic_schema
        event_date
        event_end_date
        event_location
        event_subtitle
        event_banner {
          source_url
        }

      }
        
      featured_media {
        source_url
      }
    }
    allWordpressPage(filter: {wordpress_id: {in: 2499}}) {
      edges {
        node {
          id
          title
          featured_media {
            source_url
          }
          acf {
            meta_title
            meta_description
            focus_keyphrase
            canonical_url
            dynamic_schema
            register_link {
              title
              url
              target
            }
            banner_label
            label_of_list_of_event_items
            webinar_start_date
            webinar_start_on
          }
        }
      }
    }
  }
`

// select_category below posted_date